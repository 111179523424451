
  import { Component, Mixins } from "vue-property-decorator";
  import mixinNotification from "@/mixins/mixinNotification";
  import UtilPopup from "@/components/util/Popup.vue";
  import AlmightyEmployeesubDisptableNow from "@/components/almighty/employeesub/disptable/Now.vue";

  @Component({
    components: {
      UtilPopup,
      AlmightyEmployeesubDisptableNow,
    },
  })
  export default class Main extends Mixins(mixinNotification) {
    disp_condition_func(employee) {
      return this.m.tmp_objects["notification"]["employee_ids"].indexOf(employee.id) !== -1;
    }
  }
