
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import CommonSelect from "@/components/common/Select.vue";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    CommonSelect,
    CommonText,
  }
})
export default class Control extends Mixins(utilMixins) {
  @Prop() disp_num;
  @Prop() search_key;
  @Prop() branch_id;
  @Prop() branch_hash;

  disp_num_option = {3: 3, 5: 5, 10: 10, 30: 30, 50: 50, 100: 100};
  store_disp_num = 10;
  store_search_key = null;
  store_branch_id = null;

  created() {
    this.store_disp_num = this.disp_num;
    this.store_search_key = this.search_key;
    this.store_branch_id = this.branch_id;
  }

  @Emit('changeDispNum')
  changeDispNum(val) {
    return val;
  }
  @Emit('changeSearchKey')
  changeSearchKey(val) {
    return val;
  }
  @Emit('changeBranchId')
  changeBranchId(val) {
    return val;
  }

  set tmp_disp_num(val) {
    this.store_disp_num = val;
    this.changeDispNum(val);
  }
  get tmp_disp_num() {
    return this.store_disp_num;
  }

  set tmp_search_key(val) {
    this.store_search_key = val;
    this.changeSearchKey(val);
  }
  get tmp_search_key() {
    return this.store_search_key;
  }

  set tmp_branch_id(val) {
    this.store_branch_id = val;
    this.changeBranchId(val);
  }
  get tmp_branch_id() {
    return this.store_branch_id;
  }
  

}
