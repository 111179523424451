
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinAlmighty from '@/mixins/mixinAlmighty';
import AlmightyEmployeesubChecktableMain from "@/components/almighty/employeesub/checktable/Main.vue";

@Component({
  components: {
    AlmightyEmployeesubChecktableMain,
  }
})
export default class Now extends Mixins(mixinAlmighty) {
  @Prop() value;
  get selected_employee() {
    return this.value;
  }
  set selected_employee(val) {
    this.$emit('input', val);
  }
}
