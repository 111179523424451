
  import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
  import mixinNotification from "@/mixins/mixinNotification";
  import * as util from "@/util";
  import CommonProgress from "@/components/common/Progress.vue";
  import AlmightyTableHead from "@/components/almighty/table/Head.vue";
  import CommonButton from "@/components/common/Button.vue";
  import AlmightyTableMain from "@/components/almighty/table/Main.vue";
  import AlmightyTableFoot from "@/components/almighty/table/Foot.vue";

  @Component({
    components: {
      CommonProgress,
      AlmightyTableHead,
      CommonButton,
      AlmightyTableMain,
      AlmightyTableFoot,
    },
  })
  export default class EmployeeTable extends Mixins(mixinNotification) {
    open_notification_create() {
      //ここでリセットしないと通知を二回目に発行する際に前の情報が残る
      const start_datetime = new Date();
      start_datetime.setMinutes(start_datetime.getMinutes() + 10);
      const end_datetime = new Date(start_datetime);
      end_datetime.setDate(end_datetime.getDate() + 7);
      this.m.setTmpObjects({
        name: "notification",
        val: {
          title: null,
          datetime: util.obj2datetimeNosecond(start_datetime, 5),
          end_datetime: util.obj2datetimeNosecond(end_datetime, 5),
          is_app: true,
          is_mail: true,
          employee_ids: [],
          content: null,
          importance: 0,
        },
      });
      this.m.openDialog({ name: "notification" });
    }
    open_notification_detail(notification) {
      this.m.setTmpObjects({ name: "notification", val: notification });
      this.m.openDialog({ name: "notification_detail" });
    }
  }
