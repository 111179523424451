
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import $ from "jquery";
import CommonDate from "@/components/common/Date.vue";
import CommonTime from "@/components/common/Time.vue";

@Component({
  components: {
    CommonDate,
    CommonTime,
  }
})
export default class Pager extends Vue {
  @Prop() value: string | null;

  @Prop({default: '15px'}) year_label_width: string;
  @Prop({default: '15px'}) month_label_width: string;
  @Prop({default: '15px'}) day_label_width: string;
  @Prop({default: '231px'}) max_date_width: string;
  @Prop() max_year: number;
  @Prop({default: 1930}) min_year: number;
  @Prop({default: 0}) plus_max_year: number;
  @Prop() minus_min_year: number;
  @Prop() default_year: number;

  @Prop({default: ' : '}) hour_label;
  @Prop() minute_label;
  @Prop({default: '10px'}) hour_label_width: string;
  @Prop({default: '0px'}) minute_label_width: string;
  @Prop({default: '75px'}) max_time_width: string;
  @Prop({default: false}) disp_yesterday: boolean;
  @Prop({default: true}) normal_time: boolean;
  @Prop() hour_select: number;
  @Prop({default: 0}) min_hour_select: number;
  @Prop({default: 23}) max_hour_select: number;
  @Prop() minute_select: number;

  no_watch: boolean = false;

  date: string = '';
  time: string | number = '';

  created() {
    console.log(this.value);
    this.set_value(this.value);
  }

  @Watch('value')
  on_change_value(value) {
    if (!this.no_watch)
      this.set_value(value);
    this.no_watch = false;
  }

  set_value(value) {
    if (value === null) {
      this.date = null
      this.time = null
    } else {
      var split_datetime = value.split(' ');
      if (split_datetime.length == 2) {
        this.date = split_datetime[0];
        this.time = util.formatTimestr2int(split_datetime[1]);
      }
    }
  }



  input_date(val) {
    this.date = val;
    this.emit_datetime();
  }
  input_time(val) {
    this.time = val;
    //valはint型で帰ってくる
    this.emit_datetime();
  }

  emit_datetime() {
    if (this.date !== '' && this.date !== null && this.time !== '' && this.time !== null) {
      let datetime = this.date+" "+util.formatTimeint(this.time);
      if (this.value != datetime) {
        this.no_watch = true;
      }
      this.$emit('input', datetime);
    } else {
      if (this.value != null) {
        this.no_watch = true;
      }
      this.$emit('input', null);
    }
  }
}
