
  import { Component, Mixins } from "vue-property-decorator";
  import mixinUtil from "@/mixins";
  import NotificationTable from "@/components/notification/Table.vue";
  import NotificationCreate from "@/components/notification/Create.vue";
  import NotificationDetail from "@/components/notification/Detail.vue";

  @Component({
    components: {
      NotificationTable,
      NotificationCreate,
      NotificationDetail,
    },
  })
  export default class Main extends Mixins(mixinUtil) {}
