
  import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
  import mixinEmployeeSubTable from "@/mixins/almighty/table/mixinEmployeeSubTable";
  import CommonProgress from "@/components/common/Progress.vue";
  import AlmightyTableHead from "@/components/almighty/table/Head.vue";
  import AlmightyTableMain from "@/components/almighty/table/Main.vue";
  import CommonCheck from "@/components/common/Check.vue";
  import AlmightyTableFoot from "@/components/almighty/table/Foot.vue";

  @Component({
    components: {
      CommonProgress,
      AlmightyTableHead,
      AlmightyTableMain,
      CommonCheck,
      AlmightyTableFoot,
    },
  })
  export default class EmployeeTable extends Mixins(mixinEmployeeSubTable) {
    @Prop() branch_id;
    @Prop() value;

    get tmp_selected_employee() {
      return this.value;
    }
    set tmp_selected_employee(val) {
      this.$emit("input", val);
    }

    @Emit("changeBranchId")
    changeBranchId(val) {
      return val;
    }

    setSelectedEmployee(val) {
      const index = this.tmp_selected_employee.indexOf(val);
      if (index === -1) {
        this.tmp_selected_employee.push(val);
      } else {
        this.tmp_selected_employee.splice(index, 1);
      }
    }
    resetSelectedEmployee() {
      const employee_ids = this.employees_searched_data.map((employee) => {
        return employee.id;
      });
      this.tmp_selected_employee = this.tmp_selected_employee.filter((employee_id) => {
        return employee_ids.indexOf(employee_id) === -1;
      });
    }
    setSelectedEmployees(vals) {
      vals = vals.filter((val) => {
        return this.tmp_selected_employee.indexOf(val) === -1;
      });
      this.tmp_selected_employee = this.tmp_selected_employee.concat(vals);
    }
    checkAllEmployees(val) {
      if (val) {
        const employees = this.employees_searched_data.map((employee) => {
          return employee.id;
        });
        this.setSelectedEmployees(employees);
      } else {
        this.resetSelectedEmployee();
      }
    }

    get select_employee_all() {
      const searched_employee_ids = this.employees_searched_data.map((employee) => employee.id);
      return (
        this.tmp_selected_employee.filter((employee_id) => {
          return searched_employee_ids.indexOf(employee_id) !== -1;
        }).length === searched_employee_ids.length
      );
    }
  }
