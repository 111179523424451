
  import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
  import mixinEmployeeSubTable from "@/mixins/almighty/table/mixinEmployeeSubTable";
  import CommonProgress from "@/components/common/Progress.vue";
  import AlmightyTableHead from "@/components/almighty/table/Head.vue";
  import AlmightyTableMain from "@/components/almighty/table/Main.vue";
  import AlmightyTableFoot from "@/components/almighty/table/Foot.vue";

  @Component({
    components: {
      CommonProgress,
      AlmightyTableHead,
      AlmightyTableMain,
      AlmightyTableFoot,
    },
  })
  export default class EmployeeTable extends Mixins(mixinEmployeeSubTable) {
    @Prop() branch_id;
    @Prop() light_condition_func;
    @Prop() disp_condition_func;
    @Prop({ default: "transparent" }) light_color;

    real_light_condition_func(employee_id) {
      if (this.light_condition_func) {
        return this.light_condition_func(employee_id);
      } else {
        return false;
      }
    }

    condition_func(employee) {
      return this.disp_condition_func(employee);
    }

    get style() {
      return {
        "--light-color": this.light_color,
      };
    }

    @Emit("changeBranchId")
    changeBranchId(val) {
      return val;
    }
  }
