
  import { Component, Mixins } from "vue-property-decorator";
  import mixinNotification from "@/mixins/mixinNotification";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";
  import AlmightyTableMain from "@/components/almighty/table/Main.vue";
  import CommonInfobutton from "@/components/common/Infobutton.vue";
  import CommonText from "@/components/common/Text.vue";
  import CommonCheck from "@/components/common/Check.vue";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonDatetime from "@/components/common/Datetime.vue";
  import CommonTextarea from "@/components/common/Textarea.vue";
  import AlmightyEmployeesubChecktableNow from "@/components/almighty/employeesub/checktable/Now.vue";

  @Component({
    components: {
      UtilPopup,
      AlmightyTableMain,
      CommonInfobutton,
      CommonText,
      CommonCheck,
      CommonSelect,
      CommonDatetime,
      CommonTextarea,
      AlmightyEmployeesubChecktableNow,
    },
  })
  export default class Main extends Mixins(mixinNotification) {
    created() {
      //Tableコンポーネントでもリセットしている
      const start_datetime = new Date();
      start_datetime.setMinutes(start_datetime.getMinutes() + 10);
      const end_datetime = new Date(start_datetime);
      end_datetime.setDate(end_datetime.getDate() + 7);
      this.m.setTmpObjects({
        name: "notification",
        val: {
          title: null,
          datetime: util.obj2datetimeNosecond(start_datetime, 5),
          end_datetime: util.obj2datetimeNosecond(end_datetime, 5),
          is_app: true,
          is_mail: true,
          employee_ids: [],
          content: null,
          importance: 0,
        },
      });
    }

    get tmp_notification() {
      return this.m.tmp_objects["notification"];
    }
    set tmp_notification(val) {
      this.m.setTmpObjects({ name: "notification", val });
    }

    get title_length() {
      return this.tmp_notification.title ? this.tmp_notification.title.length : 0;
    }
    get content_length() {
      return this.tmp_notification.content ? this.tmp_notification.content.length : 0;
    }
  }
