
import { Component, Prop, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';

@Component({
  components: {
  }
})
export default class Normal extends Mixins(utilMixins) {
  @Prop({default: 'normal'}) type;//normal: (1列目をスルーして2列目を固定するパターン)
  @Prop({default: 'other'}) theme;
  @Prop({default: 10}) row_num;
  @Prop({default: '100%'}) width;
  @Prop({default: '100%'}) minwidth;

  get style() {
    return {
      '--width': this.minwidth,
      '--min-width': this.width,
    };
  }
}
