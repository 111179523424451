import { Component, Mixins } from 'vue-property-decorator';
import mixinTable from '@/mixins/almighty/table/mixinTable';
import {makeTableMixin, dataDescript, validateDescript} from '@/mixins/Descriptor';
import vuex_data from '@/vuex/vuex_data';

const tableDescriptor = {
  manager: null,
  data: {
    source: 'employee_sub_now',
    key: 'employees',
    sort_key: [
      {key: 'code', keys: ['data', 'code']},
      {key: 'name', keys: ['data', 'kana']},
      {key: 'hiring_type', keys: ['data', 'hiring_type']},
      {key: 'branch_id', keys: ['data', 'branch_id']},
      {key: 'status', keys: ['data', 'status']},
      {key: 'retired_on', keys: ['data', 'retired_on']},
    ],
    default_sort_key: [
      {key: 'retired_on', keys: ['data', 'retired_on'], sort_mode: 'desc'},
    ],
    search_targets: [
      {type: 'normal', keys: ['data', 'code']},
      {type: 'normal', keys: ['data', 'name']}, 
      {type: 'kana', keys: ['data', 'kana']},
    ],
  },
}

const employeeSubNowDescriptor = {
  name: 'employee_sub_now',
  vuex: vuex_data.almighty_employee_sub_now,
  indexers: ['branch_id'],
  fetch(self) {
    return { branch_id: self.branch_id };
  },
  dependencies: ['branch_id'],
};
const branchNowDescriptor = {
  name: 'branch_now',
  vuex: vuex_data.almighty_branch_now,
  is_single: true,
  fetch(self) {
    return {};
  },
  dependencies: [],
};

@Component
export default class mixinEmployeeTable extends Mixins(makeTableMixin(tableDescriptor), mixinTable) {
  get employee_sub_now() {
    return dataDescript(this, employeeSubNowDescriptor);
  }
  get employee_sub_now_validate() {
    return validateDescript(this, employeeSubNowDescriptor);
  }
  get branch_now() {
    return dataDescript(this, branchNowDescriptor);
  }
  get branch_now_validate() {
    return validateDescript(this, branchNowDescriptor);
  }
}