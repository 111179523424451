
import { Component, Mixins } from 'vue-property-decorator';
import mixinNotification from '@/mixins/mixinNotification';
import NotificationMain from "@/components/notification/Main.vue";

@Component({
  components: {
    NotificationMain,
  }
})
export default class Notification extends Mixins(mixinNotification) {

}
