
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinAlmighty from "@/mixins/mixinAlmighty";
  import AlmightyEmployeesubDisptableMain from "@/components/almighty/employeesub/disptable/Main.vue";

  @Component({
    components: {
      AlmightyEmployeesubDisptableMain,
    },
  })
  export default class Now extends Mixins(mixinAlmighty) {
    @Prop() value;
    @Prop() light_condition_func;
    @Prop() disp_condition_func;
    get selected_employee() {
      return this.value;
    }
    set selected_employee(val) {
      this.$emit("input", val);
    }
  }
