import { Component, Mixins, Prop } from 'vue-property-decorator';
import utilMixins from '@/mixins'
import {makeUtilMixin} from '@/mixins/Descriptor';
import vuex_manager from '@/vuex/vuex_manager';
import * as util from '@/util';

const utilDescriptor = {
  manager: vuex_manager.almighty_manager,
  data: [],
}



@Component
export default class mixinAlmighty extends Mixins(makeUtilMixin(utilDescriptor), utilMixins) {
  @Prop() theme;
  store_branch_id = null;
  get branch_id() {
    var branch_id = this.store_branch_id;
    if (branch_id === null) {
      branch_id = Number(util.getLocalStorage('branch_id'));
    }
    if (branch_id != 0 && this.s_auth == 2 && !this.is_multi_branch) {
      branch_id = 0;
    }
    this.store_branch_id = branch_id;
    return branch_id;
  }
  set branch_id(val) {
    this.page = 1;
    this.store_branch_id = val;
  }
}
