
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';

@Component({
  components: {
  }
})
export default class Foot extends Mixins(utilMixins) {
  @Prop() all_index;
  @Prop() disp_num;
  @Prop() page_num;

  store_page = null;

  created() {
    this.store_page = this.page_num;
  }

  @Emit('changePage')
  changePage(val) {
    return val;
  }

  set tmp_page(val) {
    this.store_page = val;
    this.changePage(val);
  }
  get tmp_page() {
    return this.store_page;
  }

  get start_index() {
    return this.disp_num * (this.page_num - 1) + 1;
  }
  get end_index() {
    return Math.min(this.all_index, this.disp_num * this.page_num);
  }
  get max_page() {
    return Math.ceil(this.all_index / this.disp_num);
  }
  get page_num_list() {
    var start_page = Math.max(1, this.page_num - 2);
    if (this.page_num > this.max_page - 2)
      start_page = Math.max(1, this.max_page - 4)
    var end_page = Math.min(this.max_page, this.page_num + 2);
    if (this.page_num < 3)
      end_page = Math.min(this.max_page, 5);
    var page_num_list = [];
    for (var i = start_page; i <= end_page; i++) {
      page_num_list.push(i);
    }
    return page_num_list;
  }
}
